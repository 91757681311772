import React, { ChangeEvent, ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { AppointmentDays, AppointmentDaytime } from './static'
import { AppStoreContext } from '../../../utils'
import { AppTheme } from '../../../theme'
import {
  Button,
  FlexContainer,
  Link,
  Select,
  SelectOptionType,
  Space,
  Switch,
  TextField,
  Typography
} from '../../atoms'

interface ContactProps {
  onSendEmail(): void
}

// interface ContactFormResult {
//   isOwner: boolean
//   basicInfo: BasicInfo
//   visitTime: string[]
//   selectedProducts: string[]
// }

interface BasicInfo {
  // tslint:disable-next-line: prettier
  [key: string]: string | undefined
  // isOwner: boolean
  firstName: string
  lastName: string
  email: string
  tel?: string
  street?: string
  zip: string
  city: string
}

export const Contact: React.FC<ContactProps> = ({ onSendEmail }) => {
  const store = useContext(AppStoreContext)
  const [dataProtection, setDataProtection] = useState('')
  const [dataProtectionUrl, setDataProtectionUrl] = useState('')

  const [isOwner, setIsOwner] = useState(false)
  const [basicInfo, setBasicInfo] = useState({} as BasicInfo)
  const [selectedDay, setSelectedDay] = useState(AppointmentDays)
  const [selectedAppoitmentTime, setSelectedAppoitmentTime] = useState(AppointmentDaytime)
  const [selectedService, setSelectedService] = useState(store.mapDataToInterestedServices())
  const [policyAgreed, setPolicyAgreed] = useState(false)

  const [isFirstnameValid, setIsFirstnameValid] = useState(true)
  const [isLastnameValid, setIsLastnameValid] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPolicyError, setIsPolicyError] = React.useState(false)

  useEffect(() => {
    if (!!store) {
      setDataProtection(store.wtSettings.form.text.dataProtectionCheckboxText)
      setDataProtectionUrl(store.wtSettings.form.text.dataProtectionUrl)
      setSelectedService(store.mapDataToInterestedServices())
    }
  }, [store])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const basic: BasicInfo = basicInfo
    const currentField = event.currentTarget
    basic[currentField.name] = currentField.value
    setBasicInfo(basic)
    // console.log(basic, basicInfo)

    if (!!basicInfo.firstName) {
      setIsFirstnameValid(true)
    }

    if (!!basicInfo.lastName) {
      setIsLastnameValid(true)
    }

    if (!!basicInfo.email) {
      setIsEmailValid(true)
    }
  }

  const renderDataschutz = () => {
    return (
      <Typography color={isPolicyError ? AppTheme.colors.red : AppTheme.colors.darkgray}>
        <>
          <>{dataProtection + ' '}</>
          <Link href={dataProtectionUrl} target='_blank'>
            {'Datenschutzerklärung öffnen'}
          </Link>
        </>
      </Typography>
    )
  }

  const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const reformUserInputData = () => {
    const data: {
      customer: {
        [key: string]: string | boolean | { [key: string]: string }
        address: { [key: string]: string }
      }
      appointment: { [key: string]: string[] }
      interested: string[]
    } = {
      customer: { address: {} },
      appointment: {},
      interested: []
    }
    // customer
    data.customer.isOwner = isOwner
    Object.keys(basicInfo).forEach((key: string) => {
      if (key === 'street' || key === 'zip' || key === 'city') {
        data.customer.address[`${key}`] = basicInfo[key] as string
      } else {
        data.customer[`${key}`] = basicInfo[key] as string
      }
    })

    // apointment
    const days: string[] = []
    selectedDay.forEach((day: SelectOptionType) => {
      if (day.checked) {
        days.push(day.value)
      }
    })

    const daytime: string[] = []
    selectedAppoitmentTime.forEach((appointment: SelectOptionType) => {
      if (appointment.checked) {
        daytime.push(appointment.value)
      }
    })

    data.appointment.days = days
    data.appointment.daytime = daytime
    // interested

    const interested: string[] = []
    selectedService.forEach((service: SelectOptionType) => {
      if (service.checked) {
        interested.push(service.value)
      }
    })
    data.interested = interested
    return data
  }

  const handleCheckPolicyAgreement = () => {
    setPolicyAgreed(!policyAgreed)
    setIsPolicyError(!!policyAgreed)
  }

  const handleSubmit = () => {
    setIsFirstnameValid(!!basicInfo.firstName && basicInfo.firstName !== '')
    setIsLastnameValid(!!basicInfo.lastName && basicInfo.lastname !== '')
    setIsEmailValid(!!basicInfo.email && basicInfo.email !== '' && validateEmail(basicInfo.email))
    setIsPolicyError(!policyAgreed)

    if (!isFirstnameValid || !isLastnameValid || !isEmailValid || !policyAgreed) {
      const ContactAlert = ({
        onClose
      }: {
        title: string
        message: string
        onClose: () => void
      }): JSX.Element => {
        return (
          <ThemeProvider theme={AppTheme}>
            <AlertContainer onClick={onClose}>
              <div>
                <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`} lineHeight='4em'>
                  Nicht gesendet
                </Typography>
                <Typography fontSize={`${AppTheme.fonts.sizes.size_Paragraph_L}px`}>
                  Bitte korrigieren Sie die rot hinterlegten Eingaben
                </Typography>
              </div>
            </AlertContainer>
          </ThemeProvider>
        )
      }
      confirmAlert({
        customUI: ContactAlert
      })
    } else {
      onSendEmail()
      store?.sendEmail(reformUserInputData())
    }
  }

  return (
    <ContactContainer className='contact'>
      <Space py={`${AppTheme.spacingSize.XL}px`} px={`${AppTheme.spacingSize.XS}px`} height='auto'>
        <Space mb={`${AppTheme.spacingSize.L}px`}>
          <Switch label='Eigentümer' name='isOwner' setChecked={setIsOwner} checked={isOwner} />
        </Space>
        <TextField
          value={basicInfo.firstName}
          name='firstName'
          placeholder='Vorname'
          isRequired
          hasError={!isFirstnameValid}
          errorMsg={'Pflichtfeld'}
          onInputChange={handleInputChange}
        />
        <TextField
          value={basicInfo.lastName}
          name='lastName'
          placeholder='Nachname'
          isRequired
          hasError={!isLastnameValid}
          errorMsg={'Pflichtfeld'}
          onInputChange={handleInputChange}
        />
        <TextField
          value={basicInfo.email}
          name='email'
          placeholder='Email'
          isRequired
          hasError={!isEmailValid}
          errorMsg={'Email nicht gültig'}
          onInputChange={handleInputChange}
        />
        <TextField
          name='tel'
          value={!!basicInfo.tel ? basicInfo.tel : ''}
          placeholder='Telefon'
          onInputChange={handleInputChange}
        />
        <TextField
          name='street'
          value={!!basicInfo.street ? basicInfo.street : ''}
          placeholder='Strasse und Hausnummer'
          onInputChange={handleInputChange}
        />
        <TextField
          name='zip'
          value={basicInfo.zip}
          placeholder='PLZ'
          onInputChange={handleInputChange}
        />
        <TextField
          name='city'
          value={basicInfo.city}
          placeholder='Ort'
          onInputChange={handleInputChange}
        />

        <SectionContainer>
          <>
            <Typography>{'Ich bin erreichbar'}</Typography>
            <Space my={`${AppTheme.spacingSize.L}px`}>
              <Select
                isMultiple
                selectedOptions={selectedDay}
                setSelectedOptions={setSelectedDay}
                itemWidth='70%'
                isInline={false}
              />
            </Space>
            <Select
              isMultiple
              selectedOptions={selectedAppoitmentTime}
              setSelectedOptions={setSelectedAppoitmentTime}
            />
          </>
        </SectionContainer>

        <SectionContainer>
          <>
            <Typography>{'Ich interessiere mich außerdem für'}</Typography>
            <Space mt={`${AppTheme.spacingSize.L}px`}>
              <Select
                isMultiple
                selectedOptions={selectedService}
                setSelectedOptions={setSelectedService}
              />
            </Space>
          </>
        </SectionContainer>

        <SectionContainer>
          <Switch
            checked={policyAgreed}
            setChecked={handleCheckPolicyAgreement}
            label={renderDataschutz()}
            name='policy_agreed'
          />
        </SectionContainer>

        <SectionContainer>
          <FlexContainer justifyContent='space-between' alignItems='center'>
            <Typography color={AppTheme.colors.gray}>{'*Pflichtangeben'}</Typography>
            <Button onClick={handleSubmit}>{'Absenden'}</Button>
          </FlexContainer>
        </SectionContainer>
      </Space>
    </ContactContainer>
  )
}

interface SectionContainerProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

const SectionContainer: React.FC<SectionContainerProps> = ({ children }) => {
  return (
    <Wrapper>
      <Space pt={`${AppTheme.spacingSize.L}px`} pb={`${AppTheme.spacingSize.S}px`}>
        {children}
      </Space>
    </Wrapper>
  )
}

const ContactContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkgray};
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10 10 10 10;
`

export default Contact
